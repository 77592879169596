<template>
  <v-layout row wrap style="direction: ltr">
    <div id="chartdiv" ref="chartdiv" style="width: 100%; height: 400px"></div>
    <!-- <div id="chartdiv1" ref="chartdiv" style="width: 100%;
  height: 400px;"></div> -->
  </v-layout>
</template>
<script>
import * as am4core from '@amcharts/amcharts4/core';
am4core.addLicense('CH351911686');
import * as am4charts from '@amcharts/amcharts4/charts';

export default {
  name: 'GaugeChart',
  props: ['chartData', 'selectedTab'],
  data() {
    return {
      chart: null
    };
  },
  mounted() {
    this.setGraph();
  },
  methods: {
    setGraph() {
      const chart = am4core.create('chartdiv', am4charts.GaugeChart);
      // console.log(chart);
      chart.startAngle = -180;
      chart.endAngle = 180;
      const axis = chart.xAxes.push(new am4charts.ValueAxis());
      axis.renderer.useChartAngles = false;
      axis.renderer.startAngle = 360;
      axis.renderer.endAngle = 0;
      axis.min = 0;
      axis.max = 360;
      axis.renderer.rtl = false;
      // axis.renderer.reverseOrder = true;
      axis.renderer.minLabelPosition = 0;
      axis.renderer.maxLabelPosition = 0.99;
      axis.renderer.line.strokeOpacity = 1;
      axis.renderer.line.strokeWidth = 1;
      axis.renderer.inside = false;
      axis.renderer.labels.template.radius = 10;
      axis.renderer.grid.template.disabled = false;
      const range = axis.axisRanges.create();
      const axisFill = range.axisFill;
      axisFill.fillOpacity = 0.2;
      axisFill.disabled = false;
      // axisFill.fill = am4core.color("#FFFFFF");
      const magnitudeArr = [];
      for (const i of this.selectedTab) {
        magnitudeArr.push(this.chartData[1][`Phasor_${i}_MGT`]);
      }
      const max = Math.max(...magnitudeArr) * 1.1;
      if (this.selectedTab.indexOf('I1') > -1) {
        const angleI1 = this.chartData[1]['Phasor_I1_Angle'];
        const handi1 = chart.hands.push(new am4charts.ClockHand());
        handi1.zIndex = 1;
        handi1.radius = am4core.percent(
          (this.chartData[1][`Phasor_I1_MGT`] / max) * 100
        );
        handi1.startWidth = 5;
        handi1.endWidth = 5;
        handi1.fill = am4core.color('#0b41b7');
        if (angleI1 < 0) {
          handi1.showValue(360 + angleI1);
        } else {
          handi1.showValue(angleI1);
        }
      }
      if (this.selectedTab.indexOf('I2') > -1) {
        const angleI2 = this.chartData[1]['Phasor_I2_Angle'];
        const handi2 = chart.hands.push(new am4charts.ClockHand());
        handi2.zIndex = 1;
        handi2.radius = am4core.percent(
          (this.chartData[1][`Phasor_I2_MGT`] / max) * 100
        );
        handi2.startWidth = 5;
        handi2.endWidth = 5;
        handi2.fill = am4core.color('#04580f');
        if (angleI2 < 0) {
          handi2.showValue(360 + angleI2);
        } else {
          handi2.showValue(angleI2);
        }
      }
      if (this.selectedTab.indexOf('I3') > -1) {
        const angleI3 = this.chartData[1]['Phasor_I3_Angle'];
        const handi3 = chart.hands.push(new am4charts.ClockHand());
        handi3.zIndex = 1;
        handi3.radius = am4core.percent(
          (this.chartData[1][`Phasor_I3_MGT`] / max) * 100
        );
        handi3.startWidth = 5;
        handi3.endWidth = 5;
        handi3.fill = am4core.color('#b80101');
        if (angleI3 < 0) {
          handi3.showValue(360 + angleI3);
        } else {
          handi3.showValue(angleI3);
        }
      }
      if (this.selectedTab.indexOf('V1') > -1) {
        const angleV1 = this.chartData[1]['Phasor_V1_Angle'];
        const handv1 = chart.hands.push(new am4charts.ClockHand());
        handv1.zIndex = 0;
        handv1.radius = am4core.percent(
          (this.chartData[1][`Phasor_V1_MGT`] / max) * 100
        );
        handv1.startWidth = 5;
        handv1.endWidth = 5;
        handv1.fill = am4core.color('#06aca1');
        if (angleV1 < 0) {
          handv1.showValue(360 + angleV1);
        } else {
          handv1.showValue(angleV1);
        }
      }
      if (this.selectedTab.indexOf('V2') > -1) {
        const angleV2 = this.chartData[1]['Phasor_V2_Angle'];
        const handv2 = chart.hands.push(new am4charts.ClockHand());
        handv2.zIndex = 0;
        handv2.radius = am4core.percent(
          (this.chartData[1][`Phasor_V2_MGT`] / max) * 100
        );
        handv2.startWidth = 5;
        handv2.endWidth = 5;
        handv2.fill = am4core.color('#56036b');
        if (angleV2 < 0) {
          handv2.showValue(360 + angleV2);
        } else {
          handv2.showValue(angleV2);
        }
      }
      if (this.selectedTab.indexOf('V3') > -1) {
        const angleV3 = this.chartData[1]['Phasor_V3_Angle'];
        const handv3 = chart.hands.push(new am4charts.ClockHand());
        handv3.zIndex = 0;
        handv3.radius = am4core.percent(
          (this.chartData[1][`Phasor_V3_MGT`] / max) * 100
        );
        handv3.startWidth = 5;
        handv3.endWidth = 5;
        handv3.fill = am4core.color('#b57103');
        if (angleV3 < 0) {
          handv3.showValue(360 + angleV3);
        } else {
          handv3.showValue(angleV3);
        }
      }
      this.chart = chart;
    }
  },
  watch: {
    chartData(newVal) {
      this.setGraph();
    },
    selectedTab(newVal) {
      this.setGraph();
    }
  }
};
</script>
